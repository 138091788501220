.save_box[data-v-c10c0d4c] {
  margin: 0 auto;
  margin-top: 30px;
  text-align: center;
}
.top_title[data-v-c10c0d4c] {
  width: 100%;
  margin-bottom: 20px;
}
.top_title h4[data-v-c10c0d4c] {
  font-size: 20px;
  color: #333;
  font-weight: 700;
  margin: 10px auto;
  text-align: center;
}
.top_title .center_title[data-v-c10c0d4c] {
  width: 30%;
  margin: 0 auto;
  text-align: center;
}
.top_title .center_title h6[data-v-c10c0d4c] {
  font-size: 14px;
  color: #333;
  font-weight: normal;
}
.top_title .center_title input[data-v-c10c0d4c] {
  font-size: 14px;
  color: #333;
  line-height: 32px;
}
.top_title .right_num[data-v-c10c0d4c] {
  float: right;
  width: 20%;
  text-align: right;
  font-size: 14px;
  color: #333;
}
.top_title .bottom_time[data-v-c10c0d4c] {
  width: 100%;
  margin-top: 10px;
}
.top_title .top_input2[data-v-c10c0d4c] {
  width: 50%;
}
.top_title .top_input2 h6[data-v-c10c0d4c] {
  display: inline-block;
  line-height: 28px;
  font-size: 14px;
  font-weight: normal;
}
.top_title .top_input3[data-v-c10c0d4c] {
  width: 50%;
  text-align: right;
}
.top_title .top_input3 h6[data-v-c10c0d4c] {
  display: inline-block;
  line-height: 32px;
  font-size: 14px;
  font-weight: normal;
}
.content[data-v-c10c0d4c] {
  position: relative;
  margin-left: 14px;
  width: 99%;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
}
.content .addi[data-v-c10c0d4c] {
  cursor: pointer;
  position: absolute;
  top: 30px;
  left: -26px;
  font-size: 22px;
  color: var(--themeColor, #17a2b8);
}
.content span[data-v-c10c0d4c] {
  line-height: 28px;
  font-size: 14px;
}
.content tr[data-v-c10c0d4c] {
  padding: 0;
  margin: 0;
}
.content td[data-v-c10c0d4c] {
  border-right: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  line-height: 25px;
  padding: 0 6px;
  color: #333;
  font-size: 14px;
}
.content input[data-v-c10c0d4c] {
  width: 100%;
  line-height: 28px;
  padding: 0 6px;
  font-size: 14px;
}
.app-container[data-v-c10c0d4c] {
  height: 100%;
  position: relative;
  padding: 20px;
}
.center[data-v-c10c0d4c] {
  text-align: center;
  color: #333 !important;
}
.weight[data-v-c10c0d4c] {
  font-size: 14px !important;
  font-weight: 600;
}
.left[data-v-c10c0d4c] {
  text-align: left;
}
.right[data-v-c10c0d4c] {
  text-align: right;
}
.iconFa[data-v-c10c0d4c] {
  position: relative;
}
.iconFa i[data-v-c10c0d4c] {
  cursor: pointer;
  font-size: 22px;
}
.iconFa .iconfont icon-shanchu[data-v-c10c0d4c] {
  color: #F56C6C;
  position: absolute;
}
.right_box[data-v-c10c0d4c] {
  width: 25%;
  text-align: right;
  margin-bottom: 15px;
  margin-right: 16px;
  position: absolute;
  top: 10px;
  right: 3px;
}